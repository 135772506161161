.nav-menu {
background-color: #272A37;
position: relative;
z-index: 9;
min-height: 100%;
justify-content: center;
border-right: 1px solid #2F313D;
float: left;
}
/* .gg{
    float: left;
  width: 30%;
  padding: 10px;
  height: 100vh;
} */

.sidebarLogo {
margin: -10px;
}

.all-nav-items {
padding-top: 120px;
}

.nav-text {
display: flex;
justify-content: start;
align-items: center;
list-style: none;
height: 60px;
}

.nav-text a {
text-decoration: none;
display: flex;
align-items: center;
}

.nav-menu-items {
margin: 10px;
padding: 20px 20px 0px 20px;
}

.logoutIconCss {
  /*margin-top: 10px;*/
  position: absolute;
  bottom: 10%;
  stroke: #8D90A0;
 }

.logoutIconCss:hover {
  stroke: #1FC7A5;
 }
 