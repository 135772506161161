/* .according{
    background-color: green;
}

.accordion-item{
    background-color: green;

} */

.mb-2{
    background-color: black;
}