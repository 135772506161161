.nav-bar{
    width: 40%;
    margin-left: 20%;
    height: 75px;
    background: #272A37;
    border-radius: 0px 0px 15px 15px;
    border: 1px solid #2F313D;
    z-index:4;
}

.switch-nav{
    width: 215px;
    height: 75px;
    background: #272A37;
    border-radius: 0px 0px 15px 15px;
    border: 1px solid #2F313D;
    z-index:4;
    margin-right: 75px;
}

.viewer-map{
    position: relative;
    z-index: 2;
}

.maptoggle-container {
    position: fixed;
    height: 140px;
    width: 60px;
    background: #272A37;
    border-radius: 0px 15px 15px 0px;
    top: 45%;
    z-index: 3;
    transition: left .3s ease-in-out;
  }


.maptoggle-image {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 11px;
    top: 51px;
    z-index: 3;
}

.viewer-panorama {
    position: relative;
    z-index: 1;
}

.viewer-pointcloud {
    position: relative;
    z-index: 1;
}

.viewer-data {
    position: relative;
    z-index: 1;
}